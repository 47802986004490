<template>
  <div id="container">

    <section id="login">
      <div class="wrap">
        <h2 class="cts_ttl"><span>ログインできない場合</span></h2>
        <p class="alnC">
          パスワードをお忘れの場合は、パスワードを再設定する必要があります。<br>
          以下のフォームに<span class="bold">「複眼経済塾に登録済みのメールアドレス」</span>をご入力の上「パスワードを再設定する」ボタンを押してください。<br>
          折り返しパスワード再設定ページのURLを案内するメールをお送りします。
        </p>
        <div class="cts_box">
          <form @submit="submitClicked">
            <div class="forget_pswd">
              <div class="input_set">
                <div class="label">登録済みメールアドレス</div>
                <input type="email" v-model="email" placeholder="例：yamada@hukugankeizai.com" v-bind:class="{error: errors}">
                <validation-errors :errors="errors" v-if="errors"/>
              </div>
            </div>

            <p class="alnC mb20">メーラーをご利用の方は迷惑メール対策の設定の設定をご確認ください</p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="再設定URLを送信する">
            </div>
          </form>
        </div>

        <div class="cts_box">
          <p class="alnC mb20">
            「複眼経済塾に登録済みのメールアドレス」がご不明な場合は、<br class="pc">お手数ですが「お問い合わせ」ページより直接運営事務局までお問い合わせ下さい。
          </p>
          <div class="button_wrap">
            <router-link :to="{ name: 'Contacts'}" class="submit basic arrow">お問い合わせ</router-link>
          </div>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from "@/components/ValidationErrors";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors
  },
  data() {
    return {
      email: null,
      errors: null,
    }
  },
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    submitClicked(event) {
      event.preventDefault()
      this.errors = []
      this.axios
          .post(`${this.env.api_base_url}users/new_password.json`, {
            email: this.email,
          })
          .then(() => {
            this.$router.push({
              name: 'UsersNewPasswordFinish'
            })
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors
            } else if (error.response.data.error) {
              this.errors.push(error.response.data.error)
            }
          });
    }
  }
}
</script>
